<template>
    <div class="account-pages my-5">
        <div class="container" v-if="currentLocation">
            <div class="row justify-content-center">
                <div class="col-xl-5">
                    <div class="card">
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-lg-12 p-5">
                                    <div class="mx-auto">
                                        <a href="/" class="h1">
                                            {{ currentLocation.name }}
                                        </a>
                                        <p class="text-muted mt-0">Register Tenant</p>
                                    </div>
                                    <div class="authentication-form" v-if="!isSubmitted">
                                        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                            <form class="form-horizontal" @submit.prevent="handleSubmit(save)"
                                                autocomplete="nope">
                                                <b-row class="mb-3">
                                                    <b-col sm="12">
                                                        <label class="mt-2" for="inputEmail3">Name</label>
                                                    </b-col>
                                                    <b-col sm="12">
                                                        <ValidationProvider v-slot="{ errors }" name="shopname"
                                                            :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 }"
                                                            :custom-messages="customMessages">
                                                            <b-form-input v-model="currentLocationTenant.shopName"
                                                                name="shopname" autocomplete="nope"></b-form-input>
                                                            <span class="text-danger text-sm">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="mb-3">
                                                    <b-col sm="12">
                                                        <label class="mt-2" for="inputEmail3">Contact Name</label>
                                                    </b-col>
                                                    <b-col sm="12">
                                                        <ValidationProvider v-slot="{ errors }" name="contactName"
                                                            :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 }"
                                                            :custom-messages="customMessages">
                                                            <b-form-input v-model="currentLocationTenant.contactName"
                                                                name="contactName" autocomplete="nope"></b-form-input>
                                                            <span class="text-danger text-sm">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="mb-3">
                                                    <b-col sm="12">
                                                        <label class="mt-2" for="inputEmail3">Contact Mobile</label>
                                                    </b-col>
                                                    <b-col sm="12">
                                                        <ValidationProvider v-slot="{ errors }" name="contactMobile"
                                                            :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 100 }"
                                                            :custom-messages="customMessages">
                                                            <b-form-input v-model="currentLocationTenant.contactMobile"
                                                                name="contactMobile" autocomplete="nope"></b-form-input>
                                                            <span class="text-danger text-sm">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="mb-3">
                                                    <b-col sm="12">
                                                        <button type="button" class="btn btn-primary btn-block"
                                                            @click="save">Submit</button>
                                                    </b-col>
                                                </b-row>
                                            </form>
                                        </ValidationObserver>
                                    </div>
                                    <div class="mx-auto" v-if="isSubmitted">
                                        <p class="text-muted mt-0">Thank you for registration.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 text-center">
                            <p class="text-muted">
                                Copyright © 2024 | Powered By EvacOne | All rights reserved
                                <br>
                                <a target="_blank" href="https://docs.evacone.com.au/#/mobile/terms"
                                    class="text-primary font-weight-bold mr-1">Terms of Use</a> |
                                <a target="_blank" href="https://docs.evacone.com.au/#/mobile/privacy"
                                    class="text-primary font-weight-bold ml-1">Privacy Policy</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import {
    externalData
} from '@/src/state/api/pwaexternal';

import {
    customMessages
} from '@utils/validationmessage'

export default {
    data() {
        return {
            isEditing: true,
            currentLocationTenant: {},
            customMessages,
            currentLocation: null,
            isSubmitted: false
        };
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
    },
    async created() {
        await this.loadLocation();
    },
    methods: {
        async loadLocation() {
            this.currentLocation = await externalData.searchLocation(this.locationId);
        },
        cancel() {
            this.$emit("reload");
        },
        async save() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            this.currentLocationTenant.locationId = this.currentLocation.keyId
            this.currentLocationTenant.companyKeyId = this.currentLocation.companyKeyId

            let apiResult = await externalData.createTenant(this.currentLocationTenant);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.isSubmitted = true
            this.$vs.notify({
                title: "Success",
                text: "Your details have been submitted.",
                color: "success",
                position: "top-center"
            });
        }
    }
}
</script>